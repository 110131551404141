$palette-primary: (
    50: #f6f6f6,
    100: #f0f0f0,
    200: #dedede,
    300: #d0d0d0,
    400: #858585,
    500: #545454,
    600: #404040,
    700: #353535,
    800: #242424,
    900: #111111,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);
$palette-accent: (
    50: #e7e8ea,
    100: #c3c5cb,
    200: #9c9fa9,
    300: #747986,
    400: #565c6c,
    500: #383f52,
    600: #32394b,
    700: #2b3141,
    800: #242938,
    900: #171b28,
    A100: #6d8cff,
    A200: #3a64ff,
    A400: #073cff,
    A700: #0033ec,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #ffffff,
        A400: #ffffff,
        A700: #ffffff,
    ),
);
$palette-warn: (
    50: #ffe8e3,
    100: #ffc5b9,
    200: #ff9f8b,
    300: #ff795d,
    400: #ff5c3a,
    /* main red color */ 500: #ff3e17,
    600: #ff3914,
    700: #ff3111,
    800: #ff290d,
    900: #e05132,
    A100: #ffffff,
    A200: #fff5f4,
    A400: #ffc4c1,
    A700: #ffaca7,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$color-white: #ffffff;
$color-black: map-get($palette-primary, 900);

$color-primary-light: map-get($palette-primary, 50);
$color-primary-lighter: map-get($palette-primary, 100);
$color-primary-light-grey: map-get($palette-primary, 300);
$color-primary-medium: map-get($palette-primary, 400);
$color-primary-grey: map-get($palette-primary, 500);
$color-primary-dark: map-get($palette-primary, 700);

$color-red: map-get($palette-warn, 500);
$color-error: map-get($palette-warn, 900);
$color-light-red: map-get($palette-warn, A700);

// TODO: we have no yellow or green in our "warning" palette, hardcoded for now
$color-warning: #f9ab02;

$color-green: #75c41b;

// Change html variables from material

:root {
    --mdc-outlined-text-field-focus-outline-width: 1px;
    --mdc-outlined-text-field-outline-color: #{$color-primary-light-grey};
    --mdc-outlined-text-field-hover-outline-color: #{$color-primary-medium};
    --mdc-outlined-text-field-focus-outline-color: #{$color-black};
    --mat-option-hover-state-layer-color: transparent;
}

// Design guide palette

$palette-viessmann: (
    black: #111111,
    white: #ffffff,
    vitoorange: #ff3e17,
    grey: (
        00: #fcfcfc,
        10: #fafafa,
        20: #f6f6f6,
        30: #f0f0f0,
        40: #dedede,
        50: #d0d0d0,
        60: #858585,
        70: #545454,
        80: #404040,
        90: #242424,
    ),
);
